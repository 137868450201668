import { notifications } from '@mantine/notifications';
import { createApi } from '@reduxjs/toolkit/query/react';
import { generateClient } from 'aws-amplify/api';
import { GraphQLError } from 'graphql';

import { AVAILABILITIES, BOOKINGS } from './tags';

const client = generateClient({
  authMode: 'apiKey',
  headers: {
    'x-api-key': process.env.GATSBY_GRAPHQL_API_KEY ?? ''
  }
});

// Define a service using a base URL and expected endpoints
export const api = createApi({
  baseQuery: async ({ document, variables }) => {
    try {
      return (await client.graphql({
        query: document,
        variables
      })) as any;
    } catch (error: any) {
      if (process.env.NODE_ENV === 'development') {
        console.error(error);
      }

      error?.errors?.map((err: GraphQLError) =>
        notifications.show({
          message: err.message,
          title: 'An Error Occurred'
        })
      );

      return { error };
    }
  },
  endpoints: () => ({}),
  reducerPath: 'locksbook-api',
  tagTypes: [AVAILABILITIES, BOOKINGS]
});
