import type { GatsbyBrowser } from 'gatsby';

import { MantineProvider } from '@mantine/core';
import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import { Notifications } from '@mantine/notifications';
import '@mantine/notifications/styles.css';
import { Amplify } from 'aws-amplify';
import { Provider } from 'react-redux';

import './src/dayjs';
import { store } from './src/lib/store';
import { theme } from './src/theme';


Amplify.configure({
  API: {
    GraphQL: {
      apiKey: process.env.GATSBY_GRAPHQL_API_KEY || '',
      defaultAuthMode: 'userPool',
      endpoint: process.env.GATSBY_GRAPHQL_API_URL || ''
    }
  },
  Auth: {
    Cognito: {
      allowGuestAccess: true,
      identityPoolId: process.env.GATSBY_IDENTITY_POOL_ID!,
    }
  },
  Storage: {
    S3: {
      bucket: process.env.GATSBY_PUBLIC_UPLOAD_BUCKET,
      region: process.env.GATSBY_AWS_REGION
    }
  }
});

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({
  element
}) => {

  return (
    <Provider store={store}>
      {element}
    </Provider>
  );
};

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({
  element
}) => {
  return (
    <MantineProvider theme={theme}>
      <Notifications autoClose={500}  position={'top-center'}/>
      {element}
    </MantineProvider>
  );
};
