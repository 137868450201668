module.exports = [{
      plugin: require('../../../../../common/temp/node_modules/.pnpm/gatsby-plugin-manifest@5.13.1_gatsby@5.13.3_encoding@0.1.13_react-dom@18.2.0_react@18.2.0__re_5txyjr4rjcz4ykpvztvojp3tkq/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e30bc95bb24d2e61d97a1038b8f471ad"},
    },{
      plugin: require('../../../../../libs/gatsby-plugin-react-i18next-root/gatsby-browser.js'),
      options: {"plugins":[],"defaultLanguage":"fr","i18nextOptions":{"defaultNS":"common","fallbackLng":"fr","interpolation":{"escapeValue":false},"supportedLngs":["fr","en"]},"languages":["fr","en"],"siteUrl":"https://uat-app.locksbook.com"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../../../common/temp/node_modules/.pnpm/gatsby@5.13.3_encoding@0.1.13_react-dom@18.2.0_react@18.2.0__react@18.2.0_type-fest@4.15.0_typescript@5.4.4/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
